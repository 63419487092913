import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { getCookie, setCookie } from 'cookies-next'
import jwtDecode from 'jwt-decode'
import { useRouter } from 'next/router'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useIntercom } from 'react-use-intercom'

import { NotificationType, notify } from 'src/providers/NotificationProvider'

import { DonateFormValues } from 'src/components/organisms/forms/DonateForm/types'

import { authCallbackPath, homePath } from 'src/config/paths'
import { env } from 'src/env/client.mjs'
import { GiftFormValues } from 'src/pages/gift/Gift/types'
import { logError } from 'src/utils/errors'

export type AuthStateCache = AppState & {
  returnTo?: string
  donateForm?: DonateFormValues
  giftForm?: GiftFormValues
}

interface AuthStateCacheContextProps {
  cache: AuthStateCache
  setCache: (state: AuthStateCache) => void
}

const AuthStateCacheContext = createContext<AuthStateCacheContextProps>(
  {} as AuthStateCacheContextProps
)

const AUTH_COOKIE_KEY = 'AUTH_STATE'

/**
 *
 *  AuthStateCacheProvider provides an internal wrapper (tt's not consumed outside of this file)
 *  for getting and setting cache values for use when bouncing between the hosted
 *  login/signup page and parts of our app where we want to restore progress.
 *
 *  i.e. the donation form.
 *
 */
const AuthStateCacheProvider = ({ children }: { children: any }) => {
  const [cacheFromCookie, setCacheFromCookie] = useState(
    JSON.parse((getCookie(AUTH_COOKIE_KEY) as string) || '{}')
  )

  const setCache = (value: AuthStateCache) => {
    setCacheFromCookie(value)
    setCookie(AUTH_COOKIE_KEY, JSON.stringify(value))
  }

  const memoisedValue = useMemo(
    () => ({
      cache: cacheFromCookie,
      setCache
    }),
    [cacheFromCookie]
  )

  return (
    <AuthStateCacheContext.Provider value={memoisedValue}>
      {children}
    </AuthStateCacheContext.Provider>
  )
}

const useAuthStateCache = () => useContext(AuthStateCacheContext)

export interface AuthContextProps {
  isUser: boolean
  isStaff: boolean
  isAdvisor: boolean
  isLoading: boolean
  login: () => void
  loginWithCustomRedirect: (returnTo: string, guestReturnTo?: string) => void
  signup: () => void
  signupWithCustomRedirect: (returnTo: string) => void
  logout: () => void
  logoutWithCustomRedirect: (returnTo: string) => void
  refreshSession: () => void
  cache: AuthStateCache
  setCache: (state: AuthStateCache) => void
  userId?: string
  awaitingEmailVerification: boolean
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
)

enum Claims {
  ALLOWED_ROLES = 'x-hasura-allowed-roles',
  DEFAULT_ROLE = 'x-hasura-default-role',
  USER_ID = 'x-hasura-user-id'
}

enum Roles {
  AUTHENTICATED = 'authenticated',
  STAFF = 'staff',
  ADVISOR = 'advisor'
}

const HASURA_CLAIMS_NAMESPACE = 'https://hasura.io/jwt/claims'

/**
 *
 *  AuthProvider is the authentication provider for our app, responsible for the
 *  functionality exposed by the `useAuth` hook.
 *
 *  i.e. login/signup/logout, user roles, authenticated user id, loading state
 *
 */
const AuthProvider = ({ children }: { children: any }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout: auth0Logout,
    getAccessTokenSilently,
    isLoading: auth0Loading,
    user
  } = useAuth0()

  const router = useRouter()
  const { cache, setCache } = useAuthStateCache()
  const [awaitingEmailVerification, setAwaitingEmailVerification] =
    useState<boolean>(false)
  const [userId, setUserId] = useState<string | undefined>()
  const [isUser, setIsUser] = useState<boolean>(false)
  const [isStaff, setIsStaff] = useState<boolean>(false)
  const [isAdvisor, setIsAdvisor] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { shutdown } = useIntercom()

  const defaultReturnTo = useCallback(
    (): string =>
      /**
       * Default to homepage if a user is authenticating from the auth-callback page
       * (i.e. after encountering an error), so they don't end up stranded on the
       * auth-callback after redirection
       */
      router.pathname === authCallbackPath ? homePath : router.asPath,
    [router.asPath, router.pathname]
  )
  const login = useCallback(() => {
    loginWithRedirect({
      appState: { returnTo: defaultReturnTo() }
    })
  }, [defaultReturnTo, loginWithRedirect])

  const loginWithCustomRedirect = useCallback(
    async (returnTo: string, guestReturnTo?: string) => {
      if (guestReturnTo) {
        loginWithRedirect({
          'ext-guestReturnTo': `${env.NEXT_PUBLIC_APP_DOMAIN}${guestReturnTo}`, // Auth0 requires any external parameters passed through to the /authorize endpoint to be prefixed with ext-[x]
          appState: { returnTo: returnTo || defaultReturnTo() }
        })
      } else {
        loginWithRedirect({
          appState: { returnTo: returnTo || defaultReturnTo() }
        })
      }
    },
    [defaultReturnTo, loginWithRedirect]
  )

  const signup = useCallback(() => {
    loginWithRedirect({
      screen_hint: 'signup',
      appState: { returnTo: defaultReturnTo() }
    })
  }, [defaultReturnTo, loginWithRedirect])

  const signupWithCustomRedirect = useCallback(
    (returnTo: string) => {
      loginWithRedirect({
        screen_hint: 'signup',
        appState: { returnTo: returnTo || defaultReturnTo }
      })
    },
    [defaultReturnTo, loginWithRedirect]
  )

  const logout = useCallback(() => {
    shutdown()
    window.localStorage.removeItem('IS_ADMIN_MODE')
    auth0Logout({ returnTo: window.location.origin })
  }, [auth0Logout, shutdown])

  const logoutWithCustomRedirect = useCallback(
    (returnTo: string) => {
      window.localStorage.removeItem('IS_ADMIN_MODE')
      shutdown()
      auth0Logout({ returnTo })
    },
    [auth0Logout, shutdown]
  )

  const refreshSession = useCallback(() => {
    getAccessTokenSilently({ ignoreCache: true })
  }, [getAccessTokenSilently])

  // Handle loading, claim checking, and userId setting when user is authenticated
  useEffect(() => {
    const extractJwtClaims = async () => {
      try {
        const token = await getAccessTokenSilently()
        const decoded: { [key: string]: any } = jwtDecode(token)
        const claims = decoded?.[HASURA_CLAIMS_NAMESPACE]

        setUserId(claims[Claims.USER_ID] as string)
        setIsUser(
          !!(claims[Claims.ALLOWED_ROLES] as string[])?.find(
            r => r === Roles.AUTHENTICATED
          )
        )
        setIsStaff(
          !!(claims[Claims.ALLOWED_ROLES] as string[])?.find(
            r => r === Roles.STAFF
          )
        )
        setIsAdvisor(
          !!(claims[Claims.ALLOWED_ROLES] as string[])?.find(
            r => r === Roles.ADVISOR
          )
        )
      } catch (err: any) {
        logError(err)
        notify({
          message: 'There was an error logging you in',
          type: NotificationType.error
        })
        logout()
      } finally {
        setIsLoading(false)
      }
    }

    if (isAuthenticated) extractJwtClaims()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  // Handle internal loading state when user is not authenticated
  useEffect(() => {
    const loadedAndUnathenticated = !auth0Loading && !isAuthenticated
    if (loadedAndUnathenticated) setIsLoading(false)
  }, [auth0Loading, isAuthenticated])

  useEffect(() => {
    setAwaitingEmailVerification(
      isAuthenticated && !!user && !user?.email_verified
    )
  }, [isAuthenticated, user, user?.email_verified])

  const memoisedValue = useMemo(
    () => ({
      isUser,
      isAdvisor,
      isStaff,
      isLoading,
      login,
      loginWithCustomRedirect,
      signup,
      signupWithCustomRedirect,
      logout,
      logoutWithCustomRedirect,
      refreshSession,
      cache,
      setCache,
      userId,
      awaitingEmailVerification
    }),
    [
      isAdvisor,
      isUser,
      isStaff,
      isLoading,
      login,
      loginWithCustomRedirect,
      signup,
      signupWithCustomRedirect,
      logout,
      logoutWithCustomRedirect,
      refreshSession,
      cache,
      setCache,
      userId,
      awaitingEmailVerification
    ]
  )

  return (
    <AuthContext.Provider value={memoisedValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

/**
 *
 *  Auth0IntegrationProvider provides an internal wrapper (it's not consumed outside of this file)
 *  for initializing the Auth0 integration so the `useAuth0` hook can be consumed from child providers
 *
 */
const Auth0IntegrationProvider = ({ children }: { children: any }) => {
  const { cache, setCache } = useAuthStateCache()
  const router = useRouter()

  const onRedirectCallback = useCallback(
    (appState?: AuthStateCache) => {
      setCache({ ...cache, returnTo: appState?.returnTo || router.pathname })
      router.replace(appState?.returnTo || router.pathname)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cache, setCache]
  )

  return (
    <Auth0Provider
      audience='hasura'
      clientId={env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
      domain={env.NEXT_PUBLIC_AUTH0_DOMAIN!}
      onRedirectCallback={onRedirectCallback}
      redirectUri={`${env.NEXT_PUBLIC_APP_DOMAIN}${authCallbackPath}`}
    >
      <AuthProvider>{children}</AuthProvider>
    </Auth0Provider>
  )
}

/**
 *
 *  OneAuthProviderToRuleThemAll provides a convenience wrapper for composing all of the app's
 *  authentication flow together. It doesn't hold any functionality, it's really just a quirk of
 *  needing to initialize providers before their consuming `use[Stuff]` hooks can be called.
 *
 *  This is the only provider that makes sense to be exported.
 *
 */
export const OneAuthProviderToRuleThemAll = ({
  children
}: {
  children: any
}) => {
  return (
    <AuthStateCacheProvider>
      <Auth0IntegrationProvider>{children}</Auth0IntegrationProvider>
    </AuthStateCacheProvider>
  )
}
