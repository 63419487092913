import { PathArgs, buildPath } from 'src/utils/paths'

// auth routes
export const authCallbackPath = '/auth-callback'
export const signInPath = '/login'
export const signUpPath = '/register'

// public pages
export const homePath = '/'
export const forbiddenPath = '/403'
export const notFoundPath = '/404'
export const allActivitiesPath = '/activity'
export const contactPath = '/contact'
export const termsPath = '/terms'
export const privacyPolicyPath = '/privacy-policy'
export const ourTeamPath = '/about-us/#our-team'
export const leaderboardsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/leaderboards' })
export const carbonCalculatorPath = '/native-trees-carbon-calculator'
export const listRegistriesPath = '/registries'
export const listPlantingProjectsPath = '/planting-projects'
export const viewCrowdFundPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/crowdfund/[uri]' })
export const viewRegistryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/registries/[registryUri]' })
export const registryGiftPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/registries/[registryUri]/gift' })
export const registryGiftStepPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/registries/[registryUri]/gift/step/[stepIndex]'
  })
export const viewProfilePath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/profiles/[uri]' })
export const donatePath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/donate' })
export const donateStepPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/donate/step/[stepIndex]' })
export const giftPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/gift' })
export const giftStepPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/gift/step/[stepIndex]' })
export const bulkGiftPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/bulk-gift' })
export const monitoringResourcesPath = '/resources/monitoring'
export const aboutUsPath = '/about-us'
export const blogPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/blog' })
export const viewBlogPostPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/blog/[slug]' })
export const viewBlogPostCatchAllPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/blog/[...slug]' }) // Note: the spread argument is required to catch all blog articles - it aligns with the pages directory
export const howItWorksPath = '/treepool'
export const resourcesPath = '/resources'
export const resourcesForKidsPath = '/resources/#kids'
export const faqPath = '/faq' // todo
export const nativeTreesPath = '/native-trees'
export const nativeTreeSpeciesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/native-trees/[slug]' })
export const whyNativeTreesPath = '/why-native-trees'
export const committedToClimatePath = '/committed-to-climate'
export const plantersTakeActionPath = '/planters'
export const businessTakeActionPath = '/businesses'
export const individualsFamiliesTakeActionPath = '/individuals-families'
export const schoolsTakeActionPath = '/schools'
export const crowdFundTreesPath = '/crowdfund-trees'
export const giftingPath = '/gifting'
export const businessGiftingPath = '/business-gifting'
export const priceGuidePath = '/businesses/price-guide'
export const plantingEventsPath = '/events'
export const viewProjectPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/planting-projects/[uri]' })

// user pages
export const inviteAccountUserPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/account/accept-invite' })
export const dashboardPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/dashboard' })
export const accountSetupPath = '/account/onboard'
export const createAccountPath = '/account/create'
export const viewAccountDetailPath = '/account/details'
export const updateAccountDetailsPath = '/account/details/update'
export const viewAccountProfilePath = '/account/profile'
export const updateAccountProfilePath = '/account/profile/update'
export const viewAccountBillingDetailsPath = '/account/billing-details'
export const viewAccountPaymentMethodsPath =
  '/account/billing-details/payment-methods'
export const updateAccountBillingDetailsPath = '/account/billing-details/update'
export const updateAccountPaymentMethodPath =
  '/account/billing-details/payment-methods/update'
export const viewAccountUsersPath = '/account/users'
export const supporterToolkitPath = '/toolkit'
export const planterToolkitPath = '/planter-toolkit'
export const supportedProjectsPath = '/dashboard/supported-projects'

export const taxCertificatesPath = '/tax-certificates'
export const listApplicationsPath = '/applications'
export const createApplicationPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/applications/create'
  })
export const createApplicationSuccessPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/applications/create/success/[applicationId]'
  })
export const viewApplicationPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/applications/[applicationId]' })
export const applicationPlantingReportPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report'
  })
export const applicationSitesPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/sites'
  })
export const applicationProjectPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/project'
  })
export const editApplicationProjectPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/project/edit'
  })

export const createApplicationMonitoringPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]/monitorings/create'
  })
export const editApplicationPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/applications/[applicationId]/edit' })
export const listFundingPath = '/funding'
export const fundingDetailPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/funding/[token]' })
export const listMyRegistriesPath = '/my-registries'
export const createMyRegistryPath = '/my-registries/create'
export const editRegistryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/registries/[registryUri]/edit' })
export const editPlantingPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]/edit'
  })
export const createPlantingPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/create'
  })
export const viewPlantingPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]'
  })
export const viewPlantingMonitoringPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]/monitorings'
  })
export const viewPlantingPhotosPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]/photos'
  })
export const viewMonitoringEventPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/applications/[applicationId]/planting-report/[plantingId]/monitorings/[eventId]'
  })

export const listProjectsPath = '/projects'
export const createProjectPath = '/projects/create'
export const viewProjectDetailPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/projects/[projectId]' })
export const viewProjectApplicationsPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/applications'
  })
export const viewProjectPlantingsPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/plantings'
  })
export const viewProjectGalleryPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/gallery'
  })
export const viewProjectFundersPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/funders'
  })
export const editProjectDetailsPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/edit'
  })
export const viewProjectMapPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/projects/[projectId]/map'
  })
export const createPlanterRegistrationPath =
  '/account/planter-registration/create'
export const editPlanterRegistrationPath = '/account/planter-registration/edit'
export const viewAccountPlanterRegistrationPath =
  '/account/planter-registration'

// admin pages
export const adminTreePoolPath = '/admin/treepool'
export const adminReportsPath = '/admin/reports'
export const adminListApplicationsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications' })
export const adminViewApplicationPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications/[applicationId]' })
export const adminViewApplicationSitesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications/[applicationId]/sites' })
export const adminViewApplicationMatchesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications/[applicationId]/matched' })
export const adminMatchApplicationPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications/[applicationId]/match' })
export const adminApplicationProjectPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/applications/[applicationId]/project' })
export const adminApplicationRegistrationPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/registration'
  })
export const adminApplicationPlantingReportPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/planting-report'
  })

export const adminListFundingPath = '/admin/funding'
export const adminViewFundingPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/funding/[purchaseId]' })
export const adminMatchFundingPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/funding/[purchaseId]/match' })
export const adminViewFundingMatchesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/funding/[purchaseId]/matched' })
export const adminListRegistriesPath = '/admin/registries'
export const adminViewRegistryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/registries/[registryId]' })
export const adminEditRegistryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/registries/[registryId]/edit' })
export const adminCreateRegistryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/registries/create/[accountId]' })
export const adminListAccountsPath = '/admin/people'
export const adminViewAccountPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]' })
export const adminAccountUsersPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/users' })
export const adminViewAccountsUserPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/users/[userId]' })
export const adminViewAccountsMembershipsPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/people/[accountId]/users/[userId]/accounts'
  })
export const adminAccountApplicationsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/applications' })
export const adminAccountRegistriesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/registries' })
export const adminAccountPurchasesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/purchases' })
export const adminAccountPlanterRegistrationPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/planter-registration' })
export const adminCreatePurchasePath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/funding/create' })
export const adminImportBulkGiftPath = `/admin/funding/import-bulk`
export const adminBulkPath = '/admin/bulk'
export const adminViewBulkPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/bulk/[id]' })
export const adminViewBulkCertificatesPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/bulk/[id]/certificates' })
export const adminEditAccountPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/people/[accountId]/update' })
export const adminViewPlantingPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/planting-report/[plantingId]'
  })
export const adminViewPlantingMonitoringsPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/planting-report/[plantingId]/monitorings'
  })
export const adminViewPlantingPhotosPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/planting-report/[plantingId]/photos'
  })
export const adminMonitoringEventPath = (args?: PathArgs) =>
  buildPath({
    ...args,
    path: '/admin/applications/[applicationId]/planting-report/[plantingId]/monitorings/[eventId]'
  })
export const adminListProjectsPath = '/admin/projects'
export const adminViewProjectPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]' })
export const adminViewProjectPreviewPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/preview' })
export const adminViewProjectApplicationsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/applications' })
export const adminViewProjectPlantingsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/plantings' })
export const adminViewProjectGalleryPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/gallery' })
export const adminViewProjectFundersPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/funders' })
export const adminEditProjectDetailsPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/edit' })
export const adminViewProjectMapPath = (args?: PathArgs) =>
  buildPath({ ...args, path: '/admin/projects/[projectId]/map' })
